/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Aiyan's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Aiyan Humayun Portfolio",
    type: "website",
    url: "http://ashutoshhathidara.com/",
  },
};

//Home Page
const greeting = {
  title: "Aiyan Humayun",
  logo_name: "AiyanHumayun",
  // nickname: "layman_brother",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1bXRknv_h-XI_3CQ3SGPteGODtvEb7YvI/view?usp=sharing",
  // portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  // githubProfile: "https://github.com/ashutosh1919",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  // {
  //   name: "Github",
  //   link: "https://github.com/ashutosh1919",
  //   fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
  //   backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  // },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/aiyan-humayun/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/@desimcnuggets",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:aiyan.h99@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  {
    name: "Steam",
    link: "https://steamcommunity.com/id/desimcnuggets",
    fontAwesomeIcon: "fa-steam", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/desimcnuggets/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Unity Game Developer",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Conceptualized and implemented innovative hyper-casual games, driving player engagement",
        "⚡ Boosted revenue for legacy products by revamping visuals and optimizing SDKs",
        "⚡ Leveraged data analysis to optimize game performance and user engagement",
      ],
      softwareSkills: [
        {
          skillName: "Unity",
          fontAwesomeClassname: "logos-unity",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Publishing Manager",
      fileName: "FullStackImg",
      skills: [
        "⚡ Led republishing efforts for legacy games in emerging markets",
        "⚡ Provided mentorship and guidance, while crafting market-informed design documents, to support incubated game development teams",
        "⚡ Designed and executed a successful LiveOps event for 'Monster Truck Stunt,' achieving positive Return on Advertising Spend (ROAS) for 3 consecutive months and garnering over a million downloads on Google Play during the event's duration",
      ],
      softwareSkills: [
        
      ],
    },
    {
      title: "Project Manager",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Lead a team of developers and artists in the creation of engaging mobile puzzle games, utilizing Scrum methodology for efficient project management",
        "⚡ Utilizing a data-driven approach to generate new game concepts and mechanics, and the design process for mobile games, meticulously crafting every aspect from level design to sound effects",
        "⚡ Successfully launched 'Seat Em All,' a mobile puzzle game exceeding all KPIs in engagement time, level conversion, and CPI",
      ],
      softwareSkills: [
        
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Indian Institute of Information Technology Kurnool",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "iiitk_logo.png",
      alt_name: "IIITDM Kurnool",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://iiitk.ac.in",
    },
    {
      title: "Indiana University Bloomington",
      subtitle: "M.S. in Computer Science",
      logo_path: "iu_logo.png",
      alt_name: "Indiana University Bloomington",
      duration: "2021 - 2023",
      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
        "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
        "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
      ],
      website_link: "https://www.indiana.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work & Internship",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Associate Project Manager",
          company: "Mindstorm Studios",
          company_url: "https://mindstormstudios.com/",
          logo_path: "mindstorm.png",
          duration: "Feb 2024 - Present",
          location: "Lahore, Pakistan",
          description:
            "Improving ads ranking models on the core TikTok product. Experience working on modeling two-tower architectures like DeepFM, Wide & deep learning, etc. Working on Large Language Models (LLM) pretraining and Large Multi-modal Model (LMM) finetuning strategies.",
          color: "#000000",
        },
        {
          title: "Associate Publishing Manager",
          company: "Mindstorm Studios",
          company_url: "https://mindstormstudios.com/",
          logo_path: "mindstorm.png",
          duration: "Mar 2023 - Jan 2024",
          location: "Lahore, Pakistan",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Quality Assurance Tester",
          company: "Single Effect Studios",
          company_url: "https://www.singleeffectstudios.com/",
          logo_path: "SES.jpeg",
          duration: "Jan 2023 - Feb 2023",
          location: "Remote",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
        {
          title: "Associate Game Developer",
          company: "Mindstorm Studios",
          company_url: "https://mindstormstudios.com/",
          logo_path: "mindstorm.png",
          duration: "Oct 2021 - Aug 2022",
          location: "Lahore, Pakistan",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Game Development Intern",
          company: "Mindstorm Studios",
          company_url: "https://mindstormstudios.com/",
          logo_path: "mindstorm.png",
          duration: "Jul 2021 - Oct 2021",
          location: "Lahore, Pakistan",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Unity Developer Intern",
          company: "Centaur Games",
          company_url: "https://centaurgamez.com/",
          logo_path: "centaurGamez.jpeg",
          duration: "Feb 2021 - May 2021",
          location: "Lahore, Pakistan",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Explore ML Facilitator",
    //       company: "Google",
    //       company_url: "https://about.google/",
    //       logo_path: "google_logo.png",
    //       duration: "June 2019 - April 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Microsoft Student Partner",
    //       company: "Microsoft",
    //       company_url: "https://www.microsoft.com/",
    //       logo_path: "microsoft_logo.png",
    //       duration: "Aug 2019 - May 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "Mozilla Campus Captain",
    //       company: "Mozilla",
    //       company_url: "https://www.mozilla.org/",
    //       logo_path: "mozilla_logo.png",
    //       duration: "Oct 2019 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Developer Students Club Member",
    //       company: "DSC IIITDM Kurnool",
    //       company_url:
    //         "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //       logo_path: "dsc_logo.png",
    //       duration: "Jan 2018 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //       color: "#0C9D58",
    //     },
    //     {
    //       title: "Developer Program Member",
    //       company: "Github",
    //       company_url: "https://github.com/",
    //       logo_path: "github_logo.png",
    //       duration: "July 2019 - PRESENT",
    //       location: "Work From Home",
    //       description:
    //         "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
    //       color: "#181717",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Below you can see some of the projects I’ve had the opportunity of contributing to, and some of the personal-fun-things I’ve been working on.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Projects",
  description: "Some of my published projects",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "grill-it",
      name: "Grill It",
      createdAt: "2023-07-02T00:00:00Z",
      shortDesc: "Grill It was a sizzling hyper casual puzzle game prototype that's sure to leave you hungry for more! In this game, players take on the role of a master grill chef, tasked with grilling up the perfect steak. With intuitive swipe controls, players must flip the steak to cook both sides to perfection!",
      description: "Grill It is a unique and addictive hyper-casual puzzle game that challenges players to cook a cube steak to perfection using intuitive swipe controls. Players must use their strategic thinking skills to flip and cook each side of the cube steak while avoiding burning it. As players progress through the levels, they will encounter new challenges and obstacles, such as varying cooking times and different types of meat, that keep the gameplay fresh and exciting. Grill It is also designed to be accessible and easy to pick up, with intuitive swipe controls that allow players to start grilling right away. The game’s colorful and vibrant graphics create a lively and enjoyable atmosphere that is sure to put players in the mood for a cookout.",
      url: "",
      imagePath: "/assests/images/grill.jpg",
    },
    {
      id: "tiny-sheep-tycoon",
      name: "Tiny Sheep Tycoon",
      createdAt: "2023-07-02T00:00:00Z",
      shortDesc: "As a member of the team working on Tiny Sheep, a casual idle game, I helped boost revenue and improve user engagement through a variety of strategies. One of my main contributions was getting rid of bugs, and improving the user interface to make it more attractive and engaging for players.",
      description: "As a member of the team working on Tiny Sheep, a casual idle game, I helped boost revenue and improve user engagement through a variety of strategies. One of my main contributions was improving the game’s visuals and user interface to make it more attractive and engaging for players. In addition to improving the game’s visuals, I also worked on integrating software development kits (SDKs) that helped optimize the game’s performance and increase revenue. By analyzing data and making data-driven decisions, I was able to identify Grill It is a unique and addictive hyper-casareas for improvement and implement changes that resulted in a significant boost to revenue. I also worked on improving user engagement by implementing features that kept players coming back to the game, such as daily rewards and limited-time events. These features not only kept players engaged but also helped increase revenue by encouraging in-app purchases. Overall, my work on Tiny Sheep helped improve the game’s revenue and user engagement by focusing on data-driven decisions, improving visuals and UI, and implementing new features to keep players engaged. My contributions were instrumental in boosting the revenue of legacy products and ensuring their continued success in a competitive mobile gaming market. The same was done for the rest of the games from this series, including hits like Idle Miner Gold.",
      url: "https://play.google.com/store/apps/details?id=com.mindstormstudios.tinysheep",
      imagePath: "/assests/images/sheep.png",
    },
    {
      id: "rock-paper-scissors",
      name: "Rock Paper Scissors",
      createdAt: "2023-09-19T00:00:00Z",
      description: "I created a hyper casual game called “Rock, Paper, Scissors” that puts a fast-paced spin on the classic game of rock, paper, scissors. Players must race against an AI opponent while making quick decisions as the game presents them with a series of rock, paper, scissors obstacles. The game mechanics are simple but addictive, with players using buttons to select their moves and advance through the levels. The game's user interface is streamlined and colorful, with a cartoonish art style that adds to its playful tone. One of the key features of the game is its emphasis on speed and accuracy. Players must make quick decisions in order to keep up with the pace of the game. At the end of each level, there is a rock, paper, scissors battle with the AI. Overall, this is a fun and engaging take on the classic game of rock, paper, scissors that is perfectly suited for the hyper casual market. Its fast-paced gameplay, colorful visuals, and easy-to-learn mechanics make it a great choice for players of all ages and skill levels.",
      shortDesc: "I created a hyper casual game called 'Rock, Paper, Scissors' that puts a fast-paced spin on the classic game of rock, paper, scissors. Players must race against an AI opponent while making quick decisions as the game presents them with a series of rock, paper, scissors obstacles.",
      url: "https://www.youtube.com/shorts/TP3CV58G-rY",
      imagePath: "/assests/images/rock-paper-scissors.jpg",
    },
    {
      id: "maze-2048",
      name: "Maze 2048",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Maze 2048 is an engaging and entertaining hyper casual puzzle game that is inspired by the classic game 2048. Players must use their strategic thinking skills to navigate through a maze and combine numbered tiles to reach the elusive number 2048. The game features a sleek and colorful design that is easy on the eyes and visually engaging. The controls are simple and intuitive, allowing players to jump right into the action without any fuss. As players progress through the levels, the difficulty increases, providing a satisfying challenge for both casual and hardcore gamers. One of the most appealing aspects of Maze 2048 is its addictive gameplay. Players will find themselves drawn in by the game’s engaging puzzles and simple yet addictive mechanics, making it the perfect choice for a quick gaming session or a longer playthrough. Overall, Maze 2048 is a fun and accessible puzzle game that is sure to keep players entertained and engaged for hours on end. With its emphasis on strategic thinking and addictive gameplay, it is a must-play for fans of the puzzle genre.",
      shortDesc: "Maze 2048 is an engaging and entertaining hyper casual puzzle game that is a mixture of Voodoo's Roller Splat and 2048. Players must use their strategic thinking skills to find the optimal path to complete 2048 through a maze.",
      url: "",
      imagePath: "/assests/images/maze.jpg",
    },
    {
      id: "dark-night",
      name: "暗い夜(Dark Night)",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Dark Night is a psychological horror walking simulator I made as a project. The goal of the game was to find and collect notes to regain his lost memories. The user goes through a unique and potentially disturbing experience as the game explores a dark and eerie environment. The notes that the player collects contains clues to the protagonist’s past and his relationship with his family. As the player collects more notes and pieces together the story, the game becomes more intense and unsettling. The slow realization that the protagonist has killed his family is a key plot point that has a significant emotional impact on the player. The game incorporates elements of psychological horror and suspense, as well as themes of guilt, trauma, and memory.",
      shortDesc: "Dark Night is a psychological horror walking simulator I made as a project. The goal of the game was to find and collect notes to regain his lost memories.",
      url: "",
      imagePath: "/assests/images/darkNight.png",
    },
    {
      id: "revamped-ui",
      name: "Rainbow Six Siege: Revamped UI",
      createdAt: "2023-10-12T00:00:00Z",
      description: "As a university project, we recreated Rainbow Six Siege's UI with a focus on improving the UX by making the game’s interface more intuitive, user-friendly, and visually appealing. To achieve this goal, we conducted user research to identify pain points in the current interface, such as confusing navigation or difficult-to-find features. We also analyzed the game's user data, such as the most commonly used features and the frequency of user errors, to inform their redesign. In our redesign, we employed principles of user-centered design, creating a UI that prioritizes the needs and preferences of the game's players. We used techniques such as prototyping, testing, and iterating to refine their designs and ensure that they are effective in improving the game's UX. Overall, the project demonstrates the importance of considering UX when designing interfaces for complex software applications like video games, and it showcases the potential for innovative design solutions to enhance the user experience.",
      shortDesc: "As a university project, we recreated Rainbow Six Siege's UI with a focus on improving the UX by making the game's interface more intuitive, user-friendly, and visually appealing.",
      url: "https://www.youtube.com/watch?v=Hg4fCAkV-q0",
      imagePath: "/assests/images/R6UI.png",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Saratoga Ave, San Jose, CA, USA 95129",
    locality: "San Jose",
    country: "USA",
    region: "California",
    postalCode: "95129",
    streetAddress: "Saratoga Avenue",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/NvYZqa34Wye4tpS17",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
